<template>
  <component :is="location ? 'teleport' : 'clean-wrapper'" :to="'#' + location">
    <block
      v-show="!closed"
      v-bind="$props"
      ref="notificationWrapper"
      class="notification animate--faster"
      :class="notificationClass"
      @mouseenter="stopScheduledClose"
      @mouseleave="startScheduledClose">
      <div class="notification__close-icon-wrap" @click="close()">
        <div class="left">
          <span :style="{ animation: closeAnimationLeftProperty }"></span>
        </div>
        <div class="right">
          <span :style="{ animation: closeAnimationRightProperty }"></span>
        </div>
        <icon
          class="notification__close-icon"
          icon="x"
          :size="1.4"
          :theme="closeIconTheme"
          @click="close()"></icon>
      </div>

      <slot
        v-if="$slots['entireContent'] || $slots['default'] || title || content"
        name="entireContent"
        :close="close"
        :open="open">
        <block class="notification__inner flex gap-m">
          <block v-if="icon" class="notification__icon-wrap">
            <icon
              :icon="icon"
              :theme="mainIconTheme"
              :size="title ? 2 : 1.5"></icon>
          </block>
          <block
            v-if="title || content || $slots['content'] || $slots['title'] || $slots['default']"
            class="notification__text-wrap width-expand">
            <slot
              v-if="title || $slots['title']"
              name="title"
              :close="close"
              :open="open">
              <h4 class="notification__title">
                {{ translateTitleCase(title, titleParams) }}
              </h4>
            </slot>
            <div class="notification__content">
              <slot
                v-if="content || $slots['default']"
                name="default"
                :close="close"
                :open="open">
                <p>{{ translate(content, contentParams) }}</p>
              </slot>
            </div>
          </block>
          <!-- TODO: support buttons with abstract api -->
          <block
            v-if="$slots['action']"
            class=""
            style="
              margin-inline-start: auto;
              padding-inline-end: var(--margin-2xl);
            ">
            <slot name="action" :close="close" :open="open"></slot>
          </block>
        </block>
      </slot>
    </block>
  </component>
</template>

<script>
import NotificationMixin from "@/client/extensions/mixins/baseComponents/notification.js";

import { Teleport } from "vue";

export default {
  components: { teleport: Teleport },
  mixins: [NotificationMixin],
  props: {
    icon: {
      type: [Boolean, String],
      default: "chevron-inline-end",
    },
    theme: {
      type: [String, Boolean],
      default: false,
    },
    animation: {
      type: [String, Boolean],
      default: config.style.weUI.notification.defaultAnimation,
    },
    closeAnimation: {
      type: [String],
      default: config.style.weUI.notification.defaultCloseAnimation,
    },
    autoClose: {
      type: [Boolean],
      default: true,
    },
    duration: {
      type: [Boolean, Number],
      default: config.style.weUI.notification.defaultDuration,
    },
    location: {
      type: [String, Boolean],
      default: (config?.style?.weUI?.notification?.defaultPosition ? config.style.weUI.notification.defaultPosition : "notifications"), // can be also "snackbar"
    },
    contentParams: {
      type: Object,
      default: {},
    },
    titleParams: {
      type: Object,
      default: {},
    },
  },
  emits: [
    "notification:closing",
    "notification:beforeClose",
    "notification:closeStart",
    "notification:afterClose",
    "notification:beforeOpen",
    "notification:afterOpen",
  ],
  data: function () {
    return {
      closeTimeout: false,
      autoCloseStopped: false,
      closed: false,
      closing: false,
      themeTypeMap: {
        primary: "lead",
        secondary: "inverse",
        danger: "danger",
        error: "danger",
        warning: "warning",
        success: "success",
      },
      themeMap: {
        primary: "lead",
        secondary: "inverse",
        danger: "danger",
        error: "danger",
        warning: "warning",
        success: "success",
      },
    };
  },
  computed: {
    wrapperComponent() {},
    hasAutoClose() {
      return this.autoClose && this.duration;
    },
    closeAnimationMetaProperties() {
      return `${this.duration}ms linear 0s forwards running `;
    },
    closeAnimationSpeed() {
      return `${this.duration}ms`;
    },
    closeAnimationLeftProperty() {
      if (this.hasAutoClose && !this.autoCloseStopped) {
        return `circle-loader-rotate-left ${this.closeAnimationSpeed} linear`;
      }
      return "none";
    },

    closeAnimationRightProperty() {
      if (this.hasAutoClose && !this.autoCloseStopped) {
        return `circle-loader-rotate-right ${this.closeAnimationSpeed} linear`;
      }

      return "none";
    },

    closeAnimationDuration() {},
    finalAnimation() {
      if (this.closing) {
        return this.closeAnimation;
      }
      return this.animation;
    },
    notificationClass() {
      let result = [];
      let finalTheme = this.themeMap.hasOwnProperty(this.theme)
        ? this.themeMap[this.theme]
        : this.theme;

      if (finalTheme) {
        result.push("notification--" + finalTheme);
      } else {
        result.push("notification--" + this.themeTypeMap[this.type]);
      }

      if (this.animation) {
        result.push("animate__" + this.finalAnimation);
      }

      if (this.closing) {
        result.push("notification--" + "closing");
      }

      return result;
    },
    mainIconTheme() {
      if (["inverse"].includes(this.theme)) {
        return "bg-4";
      }
      return this.theme;
    },
    closeIconTheme() {
      if (["inverse", "dark"].includes(this.theme)) {
        return "bg-4";
      }
      return "gray-4";
    },
  },
  mounted() {
    //this is on mounted - SSR safe
    this.startScheduledClose();
  },
  beforeUnmount() {
    this.stopScheduledClose();
  },
  methods: {
    close(immediate = false) {
      this.$emit("notification:closing");
      this.$emit("notification:beforeClose");
      if (immediate || !this.animation) {
        this.closed = true;
        this.$emit("notification:afterClose");
        return;
      }

      this.$emit("notification:closeStart");
      this.closing = true;
      utilities.wait(505).then(() => {
        this.closed = true;
        this.closing = false;
        this.$emit("notification:afterClose");
      });
    },
    open() {
      this.$emit("notification:beforeOpen");
      this.closed = false;
      this.$emit("notification:afterOpen");
    },
    stopScheduledClose() {
      clearTimeout(this.closeTimeout);
      this.autoCloseStopped = true;
    },
    startScheduledClose() {
      clearTimeout(this.closeTimeout);
      this.autoCloseStopped = false;
      if (!this.hasAutoClose) {
        return;
      }

      this.closeTimeout = utilities.setClientTimeout(() => {
        this.close();
      }, this.duration);
    },
  },
};
</script>

<style scoped lang="scss"></style>
