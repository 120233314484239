export default {
  props: {
    /**
     * How should this animate
     * todo: this is not working fully
     * @values [animation-class-name], true, false
     **/
    animation: {
      type: [String, Boolean],
      default: true,
    },
    /**
     * Type of alert
     * @values primary, success, warning, danger
     **/
    type: {
      type: String,
      default: "primary",
    },
    /**
     * Close icon type.
     * If default, will close itselve. Can have event firing instead (self clsoe fires event also)
     * If none, no close icon will show
     * TODO: closeType:none is duplicate of selfClose...
     * @values default, event, none
     */
    closeType: {
      type: String,
      default: "default",
    },
    theme: {
      type: [String, Boolean],
      default: false,
    },
    content: {
      type: [String, Boolean],
      default: false,
    },
    title: {
      type: [String, Boolean],
      default: false,
    },
    icon: {
      type: [String, Boolean],
      default: false,
    },
  },
  data: function () {
    return {};
  },
  computed: {
    notificationClass() {
      return ["notification", "notification-" + this.type];
    },

    showClose() {
      return this.closeType !== "none";
    },
    closeAsEvent() {
      return this.closeType === "event";
    },
    selfClose() {
      return this.closeType === "default";
    },
  },
  methods: {
    close() {
      this.$s.ui.alert(this.$refs.notificationWrapper).close();
    },
  },
  created() {},
};
